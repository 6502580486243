import React from "react";
import Img from "gatsby-image";
import {Row, Col} from 'react-bootstrap';
import partnersStyles from "./partners-carousel.module.css"


const PartnersCarousel = ({photo}) => {
  return (
    <div>
      <Row>
        <Col className={partnersStyles.imageContent}>
          <Img fluid={photo} />
        </Col>
      </Row>
    </div>
  )
};

export default PartnersCarousel


