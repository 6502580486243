import React from "react"
import Layout from "../../components/layout";
import {Col, Row, Container, Button} from "react-bootstrap";
import partnersStyle from "./partners.module.css";
import {graphql} from "gatsby";
import globalStyle from "../../styles/global.module.css";
import Carousel from "react-multi-carousel";
import PartnersCarousel from "../../components/partners-carousel";
import env from "../../config/env";

const PartnersPage = ({data}) => {

  let busyEasyCubePhotos = [];
  data.busyEasyCube.edges.map(edge => {
      busyEasyCubePhotos.push(edge.node.publicURL);
  });

  let uShineBabyPhotos = [];
  data.ushinebaby.edges.map(edge => {
    uShineBabyPhotos.push(edge.node.publicURL);
  });
  return (
    <Layout className="site-content"
            structuredData={{
              "@context": "https://schema.org",
              "@type": "ItemList",
              "numberOfItems": "2",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "image": [env.domain + busyEasyCubePhotos[0],
                    env.domain + busyEasyCubePhotos[1],
                    env.domain + busyEasyCubePhotos[2]
                  ],
                  "url": "https://www.momsonmaternity.com/shop/partners/",
                  "name": "Busy Easy Toys",
                  "description": "This brand was created by a Mom of a very active toddler. She wanted to find a perfect way " +
                    "to interact her little girl without going to technology or the status-quo plastic toys. Every parent knows " +
                    "how hard it is to keep your baby/toddler really busy - no matter if it’s during lunch at a restaurant, on a " +
                    "road trip or long flight; or just when you simply need to work or get something done around the house.\n" +
                    "Busy Easy Cube designs interactive handmade toys that keep little ones occupied while developing their motor " +
                    "and sensory skills. Our cubes and cube attachments are perfect for the Mama on the go!",
                  "position": 1,
                },
                {
                  "@type": "ListItem",
                  "image": [env.domain + uShineBabyPhotos[0],
                    env.domain + uShineBabyPhotos[1],
                    env.domain + uShineBabyPhotos[2]
                  ],
                  "url": "https://www.momsonmaternity.com/shop/partners/",
                  "name": "USHINEBABY",
                  "description": "USHINEBABY is a small business run by Anna Tyson, a Ukrainian Mom, who lives in sunny San Diego." +
                    " Anna’s Mom used to make bonnets just like these by USHINEBABY when she was a little girl. " +
                    "Anna wanted to relive her happy childhood, summer-time memories and has done so by creating a brand with " +
                    "unique and creative hat designs.\n" +
                    "Check out the hand made bonnets for girls 6 months up to 5 years. ",
                  "position": 2,
                }
              ]
            }}>
      <Container>
        <Row className={["justify-content-center"].join(" ")}>
          <Col md={12} className={partnersStyle.partnersPageContent}>
            <h2 className={globalStyle.entryTitle}>Smart and stylish for you and your baby</h2>
            <Row className={partnersStyle.brandContent}>
              <Col md={3} className={partnersStyle.leftSide}>
                <h4 className={partnersStyle.brandName}>
                  BUSY EASY TOYS
                </h4>
                <p className={partnersStyle.brandSlogan}>Toys for Learning and Imagination</p>
                <Button className={[globalStyle.btnPrimary, partnersStyle.shopButton]}
                        href="https://busyeasytoys.com" role="button" target="_blank" rel="noopener noreferrer">SHOP NOW</Button>
              </Col>
              <Col md={9} className={partnersStyle.rightSide}>
                <Row className={partnersStyle.carouselContent}>
                  <Col>
                    <Carousel
                      additionalTransfrom={0}
                      arrows
                      autoPlaySpeed={2000}
                      autoPlay={true}
                      centerMode={false}
                      className=""
                      containerClass="container-with-dots"
                      dotListClass=""
                      draggable
                      focusOnSelect={false}
                      infinite
                      itemClass=""
                      keyBoardControl
                      minimumTouchDrag={80}
                      renderButtonGroupOutside={false}
                      renderDotsOutside={false}
                      responsive={{
                        desktop: {
                          breakpoint: {
                            max: 3000,
                            min: 1024
                          },
                          items: 3,
                          partialVisibilityGutter: 40
                        },
                        mobile: {
                          breakpoint: {
                            max: 464,
                            min: 0
                          },
                          items: 1,
                          partialVisibilityGutter: 30
                        },
                        tablet: {
                          breakpoint: {
                            max: 1024,
                            min: 464
                          },
                          items: 2,
                          partialVisibilityGutter: 30
                        }
                      }}
                      showDots={true}
                      sliderClass=""
                      slidesToSlide={1}
                      swipeable
                    >
                      { data.busyEasyCube.edges.map(edge => (
                        <div key={edge.node.name}>
                          <PartnersCarousel
                            photo={edge.node.childImageSharp.fluid}
                          >
                          </PartnersCarousel>
                        </div>
                      ))}
                    </Carousel>

                    <div className={partnersStyle.aboutBusiness}>
                      <p>
                        This brand was created by a Mom of a very active toddler. She wanted to find a perfect way to interact
                        her little girl without going to technology or the status-quo plastic toys. Every parent knows how hard
                        it is to keep your baby/toddler really busy - no matter if it’s during lunch at a restaurant, on a road
                        trip or long flight; or just when you simply need to work or get something done around the house.
                        <a href="https://busyeasytoys.com" role="button" target="_blank" rel="noopener noreferrer"
                           className={globalStyle.redirectLink}> BUSY EASY CUBE </a>
                        designs interactive handmade toys that keep little ones occupied while developing their
                        motor and sensory skills. Our cubes and cube attachments are perfect for the Mama on the go!
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <hr className={partnersStyle.breakLine}/>

            <Row className={partnersStyle.brandContent}>
              <Col md={9} className={partnersStyle.rightSide}>
                <Row className={partnersStyle.carouselContent}>
                  <Col>
                    <Carousel
                      additionalTransfrom={0}
                      arrows
                      autoPlaySpeed={2000}
                      autoPlay={true}
                      centerMode={false}
                      className=""
                      containerClass="container-with-dots"
                      dotListClass=""
                      draggable
                      focusOnSelect={false}
                      infinite
                      itemClass=""
                      keyBoardControl
                      minimumTouchDrag={80}
                      renderButtonGroupOutside={false}
                      renderDotsOutside={false}
                      responsive={{
                        desktop: {
                          breakpoint: {
                            max: 3000,
                            min: 1024
                          },
                          items: 3,
                          partialVisibilityGutter: 40
                        },
                        mobile: {
                          breakpoint: {
                            max: 464,
                            min: 0
                          },
                          items: 1,
                          partialVisibilityGutter: 30
                        },
                        tablet: {
                          breakpoint: {
                            max: 1024,
                            min: 464
                          },
                          items: 2,
                          partialVisibilityGutter: 30
                        }
                      }}
                      showDots={true}
                      sliderClass=""
                      slidesToSlide={1}
                      swipeable
                    >
                      { data.ushinebaby.edges.map(edge => (
                        <div key={edge.node.name}>
                          <PartnersCarousel
                            photo={edge.node.childImageSharp.fluid}
                          >
                          </PartnersCarousel>
                        </div>
                      ))}
                    </Carousel>

                    <div className={partnersStyle.aboutBusiness}>
                      <p>
                        <a href="https://ushinebaby.com/" role="button" target="_blank" rel="noopener noreferrer"
                           className={globalStyle.redirectLink}>USHINEBABY</a> is a small business run by Anna Tyson, a Ukrainian Mom, who lives in sunny San Diego.
                        Anna’s Mom used to make bonnets just like these by USHINEBABY when she was a little girl.
                        Anna wanted to relive her happy childhood, summer-time memories and has done so by creating a brand
                        with unique and creative hat designs.
                        Check out the hand made bonnets for girls 6 months up to 5 years.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={3} className={partnersStyle.leftSide}>
                <h4 className={partnersStyle.brandName}>
                  USHINEBABY
                </h4>
                <p className={partnersStyle.brandSlogan}>Made with love, made for loves</p>
                <Button className={[globalStyle.btnPrimary, partnersStyle.shopButton]}
                        href="https://ushinebaby.com/" role="button" target="_blank" rel="noopener noreferrer">SHOP NOW</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className={partnersStyle.contactMessage}>
                Do you have a shop or service, Mama’s need to know about?
                Contact <a className={globalStyle.redirectLink} href="mailto:aimeecruz@momsonmaternity.com">Aimee!</a> We want to help!
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
};


export const query = graphql`
  query {
    busyEasyCube: allFile(filter: {relativePath: {regex: "/market/partners-images/busy-easy-cube\/.*/"}}) {
      edges {
        node {
          publicURL
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
    ushinebaby: allFile(filter: {relativePath: {regex: "/market/partners-images/ushinebaby\/.*/"}}) {
      edges {
        node {
          publicURL
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
  }
`;

export default PartnersPage
